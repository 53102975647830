import React from 'react'
import styled from 'styled-components'
import { AppCookiesNavbar } from '../components/AppCookiesNavbar'
import { AppContractsFooter } from '../components/AppContractsFooter'
import { AppCookiePolicyBanner } from '../components/AppCookiePolicyBanner'
import { CookiesPolicy } from '../components/CookiesPolicy'

const CookiePolicyWrapper = styled.div`
  font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const cookiepolicy = () => {
  return (
    <CookiePolicyWrapper>
      <AppCookiesNavbar />
      <AppCookiePolicyBanner />
      <CookiesPolicy />
      <AppContractsFooter />
    </CookiePolicyWrapper>
  )
}

export default cookiepolicy
