import React from 'react'
import styled from 'styled-components'

const CookiesPolicyWapper = styled.div``

export const CookiesPolicy = () => {
  return (
    <CookiesPolicyWapper>
      <div>Hello CookiesPolicy</div>
      <div>Hello CookiesPolicy</div>
    </CookiesPolicyWapper>
  )
}
