import React from 'react'
import CookiesNavbar from '../Data/CookiesNavbar.json'
import { AppPrivacyNavBar } from './AppPrivacyNavBar'

export const AppCookiesNavbar = () => {
  return (
    <div>
      {CookiesNavbar.map((data, index) => {
        return (
          <div key={index}>
            <AppPrivacyNavBar
              ImageSize={data.ImagePath}
              ImageAltName={data.AltName}
              ParaOne={data.ParaOne}
              buttonNameOne={data.buttonNameOne}
              buttonNameTwo={data.buttonNameTwo}
              UrlResponve={data.UrlResponve}
            />
          </div>
        )
      })}
    </div>
  )
}
