import React from 'react'
import styled from 'styled-components'
import { ButtonCompact } from './Button'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { LinkHubspot } from './LinkHubspot'

const NavbarWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .DownSection {
    margin-left: -8%;
    @media (max-width: 1321px) {
      margin-left: 0%;
    }
  }
  .ButtonSection {
    display: flex;
    @media (max-width: 520px) {
      justify-content: center;
    }
  }
  .paraSection {
    margin-top: 9%;
    @media (max-width: 668px) {
      margin-top: 0%;
      text-align: center;
    }
  }
  .navbuttonOne {
    width: 250px;
    height: 50px;
    background-color: #34a1d5;
    border: none;
    color: #fff;
    border-radius: 10px;
    @media (max-width: 520px) {
      width: 200px;
      font-size: 18px;
    }
    @media (max-width: 520px) {
      font-size: 15px;
    }
  }
  .navbuttonTwo {
    width: 250px;
    height: 50px;
    background-color: #34a1d5;
    border: none;
    border-radius: 10px;
    color: #fff;
    margin-left: 20%;
    @media (max-width: 768px) {
      margin-left: 8%;
    }
    @media (max-width: 520px) {
      display: none;
    }
  }
  .NavSection {
    display: flex;
    @media (max-width: 668px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .dropImage {
    height: auto;
    width: 30%;
    margin-top: 20px;
    display: flex;
    margin: 0px;
    float: left;
    @media (max-width: 668px) {
      width: 50%;
    }
    @media (max-width: 420px) {
      width: 70%;
    }
  }
  .DropImg {
    height: 200px;
    @media (max-width: 768px) {
      height: auto;
    }
    @media (max-width: 420px) {
      height: 170px;
    }
  }
  button {
    font-size: 1.2rem;
  }
  button:focus {
    outline: none;
  }
`

export const AppPrivacyNavBar = (props: any) => {
  return (
    <NavbarWapper>
      <div className='NavSection'>
        <div className='dropImage'>
          <ReuseImgTag ImagePath={props.ImageSize} AltName={props.ImageAltName} ImageStyle='DropImg' />
        </div>
        <div className='DownSection'>
          <ReUsePtag para={props.ParaOne} paraStyle='paraSection' />
          <div className='ButtonSection'>
            <div style={{ marginRight: '20px' }}>
              <LinkHubspot to='/'>
                <ButtonCompact cta={props.buttonNameOne} />
              </LinkHubspot>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <LinkHubspot to={props.UrlResponve}>
                <ButtonCompact cta={props.buttonNameTwo} />
              </LinkHubspot>
            </div>
          </div>
        </div>
      </div>
    </NavbarWapper>
  )
}
