import React from 'react'
import styled from 'styled-components'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'
import CookieBannerData from '../Data/CookieBannerData.json'

const AppCookiePolicyBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .PrivacyBannerImageStyle {
    display: flex;
    justify-content: center;
    padding-bottom: 3%;
    padding-top: 3%;
    @media (max-width: 1321px) {
      padding: 10%;
    }
  }
  .PrivacyBannerImage {
    height: auto;
    width: 65%;
    @media (max-width: 1321px) {
      width: 100%;
    }
  }
  .PrivacyBannerHeading {
    font-size: 2.5rem;
    padding: 2% 8% 1% 8%;
  }
`

export const AppCookiePolicyBanner = () => {
  return (
    <AppCookiePolicyBannerWapper>
      {CookieBannerData.map((data, index) => {
        return (
          <div key={index}>
            <div className='PrivacyBannerImageStyle'>
              <ReuseImgTag ImagePath={data.ImagePath} ImageStyle='PrivacyBannerImage' />
            </div>
            <ReUseHTwotag Heading={data.Title} HeadingStyle='PrivacyBannerHeading' />
          </div>
        )
      })}
    </AppCookiePolicyBannerWapper>
  )
}
