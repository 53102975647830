import React from 'react'
import styled from 'styled-components'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUsePtag } from './ReUsePtag'
import { LinkHubspot } from './LinkHubspot'
import { ButtonCompact } from './Button'
import { ReUseAnchorTag } from './ReUseAnchorTag'

const AppContractsFooterWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .FooterSection {
    margin-top: 3%;
  }
  .FooterImage {
    width: 17%;
    margin: auto;
    display: flex;
    @media (max-width: 668px) {
      width: 70%;
      margin-top: 10%;
    }
  }
  .FooterPara {
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 3%;
  }
  .ButtonSection {
    display: flex;
    justify-content: center;
    padding: 2rem 2rem 2rem 2rem;
    @media (max-width: 668px) {
      flex-wrap: wrap;
    }
  }
  .VisitButton {
    padding-right: 2rem;
    @media (max-width: 668px) {
      padding-right: unset;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .VisitPage {
    padding-left: 2rem;
    @media (max-width: 668px) {
      padding-left: unset;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .iconimg {
    text-decoration: none;
  }
`

export const AppContractsFooter = (props: any) => {
  return (
    <AppContractsFooterWapper>
      <div className='FooterSection'>
        <ReuseImgTag
          ImageStyle='FooterImage'
          ImagePath='https://storage.googleapis.com/raindroppublic/website_data/raindrop_full_logo.svg'
          AltName='FooterLogo'
        />
        <div className='ButtonSection'>
          <div className='VisitButton'>
            <LinkHubspot to='/'>
              <ButtonCompact cta='Visit Raindrop.com' />
            </LinkHubspot>
          </div>
          <div className='VisitPage'>
            <LinkHubspot to={props.VisitPageUrl}>
              <ButtonCompact cta="No Thanks, I'll stay Here" />
            </LinkHubspot>
          </div>
        </div>
        <ReUseAnchorTag href='mailto:info@raindrop.com' target='_blank' rel='noopener noreferrer' anchorStyle='iconimg'>
          <ReUsePtag para='info@raindrop.com' paraStyle='FooterPara' />
        </ReUseAnchorTag>
      </div>
    </AppContractsFooterWapper>
  )
}
