import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'

const ReUsePtagWapper = styled.div`
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
  }
`

export const ReUsePtag = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <ReUsePtagWapper>
      <motion.p
        id={props.id}
        className={props.paraStyle}
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 0.5, delay: 0.35 }}>
        <span className={props.IconStyle}>{props.Icon}</span>
        {props.para}
      </motion.p>
    </ReUsePtagWapper>
  )
}
